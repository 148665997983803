<template>
  <div class="round">
    <div id="container"></div>
    <div class="showContent">
      <div class="content" v-for="(item, index) in roundData" :key="index">
        <div class="name">{{ item.name }}</div>
        <div class="detail">
          <div class="distance">{{ item.distance }}米</div>
          <div class="address">{{ item.address }}</div>
        </div>
        <div
          class="nav"
          @click="toNavigationFn(item.name, item.address, item.location)"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import { roundSearchApi } from "../../request/api/index";
import { Indicator } from "mint-ui";
export default {
  data() {
    return {
      mapObj: null, //初始化地图
      resLocation: [], //定位的坐标
      roundData: [], //周边数据
      markers: [], //创建标记点
    };
  },
  methods: {
    //初始化地图
    initMap() {
      let that = this;
      this.mapObj = new AMap.Map("container");
      // 监听地图缩放结束事件
      AMap.event.addListener(this.mapObj, "zoomend", function () {
        console.log(that.mapObj.getZoom());
        // 如果用分层且不聚合的方式展示   此监听事件需要删除老的点 新增当前层级的点
        // that.mapMoveEnd();
      });
      // 缩放组件;
      this.mapObj.plugin(["AMap.ToolBar"], function () {
        that.mapObj.addControl(
          new AMap.ToolBar({
            position: "RB",
            //locate:true
            liteStyle: true,
            offset: new AMap.Pixel(10, 400),
          })
        );
      });
      this.initLocation();
    },
    //创建marker点位
    createMarker() {
      //定义marker点集合
      let markerArr = this.roundData;
      let markerIcon;
      console.log(markerArr);
      for (var i = 0; i < markerArr.length; i++) {
        // 点标记显示内容，HTML要素字符串
        var markerWc =
          "" +
          '<div class="custom-content-marker">' +
          `<div class="contain"></div>` +
          "</div>";
        var markerJy =
          "" +
          '<div class="custom-content-marker">' +
          `<div class="contain-active"></div>` +
          "</div>";
        if (markerArr[i].typecode == "200300") {
          markerIcon = markerWc;
        } else {
          markerIcon = markerJy;
        }
        let arr = markerArr[i].location.split(",");
        let marker = new AMap.Marker({
          position: arr, //marker点的位置
          content: markerIcon,
          offset: new AMap.Pixel(-13, -30), //marker内容的偏移量
          extData: {
            pioData: markerArr[i],
          },
        });
        marker.setMap(this.mapObj); //添加marker到地图
      }
    },
    //精确定位自身的位置
    initLocation() {
      Indicator.open({
        text: "Loading...",
      });
      var geolocation;
      var that = this;
      geolocation = new AMap.Geolocation({
        enableHighAccuracy: true, // 是否使用高精度定位，默认:true
        timeout: 10000, // 超过10秒后停止定位，默认：无穷大
        maximumAge: 0, // 定位结果缓存0毫秒，默认：0
        convert: true, // 自动偏移坐标，偏移后的坐标为高德坐标，默认：true
        showButton: false, // 显示定位按钮，默认：true
        buttonPosition: "LB", // 定位按钮停靠位置，默认：'LB'，左下角
        buttonOffset: new AMap.Pixel(10, 20), // 定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
        showMarker: true, // 定位成功后在定位到的位置显示点标记，默认：true
        showCircle: true, // 定位成功后用圆圈表示定位精度范围，默认：true
        panToLocation: true, // 定位成功后将定位到的位置作为地图中心点，默认：true
        zoomToAccuracy: true, // 定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
      });
      this.mapObj.addControl(geolocation);
      geolocation.getCurrentPosition();
      AMap.event.addListener(geolocation, "complete", that.onComplete); // 返回定位信息
      AMap.event.addListener(geolocation, "error", that.onError); // 返回定位出错信息
    },
    onComplete(obj) {
      this.resLocation = obj.position;
      console.log(this.resLocation);
      this.getRoundData(); //获取周边数据
    },
    onError(obj) {
      alert(obj.info + "--" + obj.message);
      console.log(obj);
    },
    //跳转到导航
    toNavigationFn(name, address, location) {
      let arr = location.split(",");
      let latitude = Number(arr[0]);
      let longitude = Number(arr[1]);
      console.log(arr);
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        //在微信就用微信地图
        this.commonFun.openLocation({
          name: name, // 位置名
          latitude: latitude, // 纬度，浮点数，范围为90 ~ -90
          longitude: longitude, // 经度，浮点数，范围为180 ~ -180。
          address: address, // 地址详情说明
          scale: 12, // 地图缩放级别,整形值,范围从1~28。默认为最大
          infoUrl: "", // 在查看位置界面底部显示的超链接,可点击跳转
        });
      }
    },
    //请求周边的数据
    getRoundData() {
      roundSearchApi(this.resLocation).then((res) => {
        console.log(res);
        if (res.status == "200") {
          this.roundData = res.data.pois;
          console.log(this.roundData);
          this.createMarker();
        }
      });
      Indicator.close();
    },
  },
  mounted() {
    this.initMap();
  },
};
</script>
<style lang="less">
.round {
  position: relative;

  #container {
    width: 100%;
    height: 100vh;
    margin: 0 auto;

    // 去除地图logo
    .amap-logo {
      display: none !important;
    }

    .amap-copyright {
      opacity: 0;
    }

    //marker的样式
    .contain {
      width: 4rem;
      height: 4.5rem;
      background: url("./image/cesuo.svg") no-repeat;
      background-size: 100%;
      border-radius: 50%;
      color: #000;
      text-align: center;
      line-height: 40px;
      font-size: 16px;
    }

    .contain-active {
      width: 4rem;
      height: 4.5rem;
      background: url("./image/jisyou.svg") no-repeat;
      background-size: 100%;
      border-radius: 50%;
      color: #fff;
      text-align: center;
      line-height: 40px;
      font-size: 16px;
    }
  }

  .showContent {
    width: 100%;
    height: 28rem;
    background: #fff;
    position: absolute;
    bottom: 0;
    overflow: auto;

    .content {
      width: 100%;
      height: 7rem;
      border-bottom: 0.1rem solid #eee;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;

      .name {
        height: 2rem;
        font-size: 1.5rem;
        line-height: 2rem;
        color: darkorange;
      }

      .detail {
        width: 30rem;
        height: 2rem;

        .distance {
          width: 5rem;
          height: 2rem;
          line-height: 2rem;
          font-size: 1.5rem;
          color: cornflowerblue;
          margin-right: 1rem;
          float: left;
        }

        .address {
          float: left;
          width: 24rem;
          height: 2rem;
          line-height: 2rem;
          font-size: 1.5rem;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        }
      }

      .nav {
        width: 3rem;
        height: 3rem;
        position: absolute;
        top: 50%;
        margin-top: -1.5rem;
        right: 2rem;
        background: url("./image/icon_nav.svg") no-repeat;
        background-size: 100%;
      }
    }
  }
}
</style>